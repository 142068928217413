import { defaultFirebaseAuth } from 'config/firebase-config';
import {
  confirmPasswordReset,
  getIdToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useFormik } from 'formik';
import { TypeAppErrors } from 'models/app.interface';
import { IAppReducer } from 'models/reducer.interface';
import { resetCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { resetCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { setUser } from 'pages/sing-in/store/actions/auth.types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AESEncryptDecryptService } from 'shared/services/aes-encrypt-decrypt.service';
import { googleLogin } from 'shared/services/login-google.service';
import { sendLog } from 'shared/services/logs.service';
import {
  authUserGoogle,
  loginUser,
  updatePasswordUser,
} from 'shared/services/users';
import { setStateError } from 'store/actions/app.types';
import { setBranch } from 'store/actions/branch.types';
import {
  IFormUpdatepassword,
  IFormUpdatepasswordErros,
} from '../models/Update-password';

const useUpdatePassword = () => {
  const { oobCode } = useParams();
  const dispatch = useDispatch();
  const {
    checkoutReducer: { auth_id, email: userEmail, name },
  } = useSelector((state: IAppReducer) => state);
  const navigate = useNavigate();
  const [userData] = useState<{ email: string; name: string }>({
    email: userEmail as string,
    name: name as string,
  });
  useEffect(() => {
    dispatch(resetCreateAccountInformation());
  }, []);

  const formUpdatePasswordInitialValues: IFormUpdatepassword = {
    email: oobCode ? '' : (userEmail as string),
    password: '',
    confimrPassword: '',
  };
  const formUpdatePasswordValidate = (values: IFormUpdatepassword) => {
    const errors: IFormUpdatepasswordErros = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'No es un correo valido, debe tener @ y dominio';
    }
    if (!values.password) {
      errors.password = 'Este campo es requerido';
    } else if (values.password.length < 6) {
      errors.password = 'Este campo debe tener mínimo 6 caracteres';
    }

    if (values.password !== values.confimrPassword) {
      errors.confimrPassword = 'Las contraseñas no coinciden';
    }
    return errors;
  };

  const formUpdatePassword = useFormik({
    initialValues: formUpdatePasswordInitialValues,
    validate: formUpdatePasswordValidate,
    onSubmit: async (values) => {
      try {
        const textEncrypt = new AESEncryptDecryptService();
        if (oobCode) {
          await confirmPasswordReset(
            defaultFirebaseAuth,
            oobCode as string,
            values.password
          );
        } else {
          await updatePasswordUser(
            values.email,
            auth_id as string,
            textEncrypt.encrypt(values.password)
          );
        }
        await handleLoginWithEmailAndPassword(values.email, values.password);
        navigateSettings();
        if (!oobCode) {
          dispatch(resetCheckoutInformation());
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // TODO Manejar error
        const data = {
          nameError: TypeAppErrors.USERINTERACTIONISSUE,
          error: {
            type: 'Error actualizando contraseña',
            message: error.message,
            data: error.stack || error.message,
          },
          payload: {
            scope: 'PurchaseOrder',
            action: 'click',
            url: window.location.href,
            user: values.email as string,
            method: `${
              oobCode ? 'confirmPasswordReset' : 'updatePasswordUser'
            }`,
          },
        };
        sendLog(data);
        dispatch(setStateError(true));
      }
    },
  });

  const handleGoogleLogin = async () => {
    try {
      const { data } = await googleLogin();
      const user = data?.user;
      const idToken = await getIdToken(user);
      const userAuth = await authUserGoogle(idToken);
      dispatch(
        setUser({
          ...user,
          accessToken: userAuth.jwt,
          name: userAuth.user.name,
          lastname: userAuth.user.lastname,
          userAuth: userAuth.user,
          companyId: userAuth.user.company.id,
          formToShow: userAuth.user.company?.formToShow || null,
        })
      );
      if (!oobCode) {
        navigateSettings;
      }
    } catch (error) {
      handleErrorPopUp();
    }
  };
  const navigateSettings = () => {
    navigate('/settings', {
      state: {
        infoPopUp: {
          showPopUp: true,
          email: userData.email,
          name: userData.name,
        },
      },
    });
  };

  const setBranchDefault = async (branch: string) => {
    dispatch(setBranch(branch));
  };

  const handleLoginWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        defaultFirebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      const userAuth = await loginUser(user);
      dispatch(
        setUser({
          ...user,
          name: userAuth.user.name,
          lastname: userAuth.user.lastname,
          userAuth: userAuth.user,
          accessToken: userAuth.jwt,
          agentId: userAuth.user.agentId,
          companyId: userAuth.user.company.id,
          formToShow: userAuth.user.company?.formToShow || null,
        })
      );
      formUpdatePassword.resetForm();
      setBranchDefault(userAuth.user.country.acronym);
    } catch (error) {
      handleErrorPopUp();
    }
  };

  const handleErrorPopUp = () => {
    dispatch(setStateError(true));
  };

  return { formUpdatePassword };
};
export default useUpdatePassword;
