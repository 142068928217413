import { Images } from 'assets/images/images.index';
import * as CSS from 'csstype';
import { clearInput } from 'helpers/segment.helper';
import { useState } from 'react';
import { ButtonDelete } from '../Button-delete/Button-delete';
import { Tooltip } from '../Tooltip/Tooltip';
import './Input.scss';

export type TypeInput =
  | 'email'
  | 'tel'
  | 'text'
  | 'number'
  | 'decimal'
  | 'password'
  | 'date'
  | 'charcode'
  | 'plate'
  | 'alphanumeric';
export interface IPropsInput {
  type: TypeInput;
  name: string;
  id: string;
  placeholder?: string;
  isDisabled?: boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: React.FocusEvent<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur: (e: React.FocusEvent<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickDeleteAction?: (e: any) => void;
  value: string | number | undefined;
  errorLabel?: string | undefined;
  errorInput: string | undefined;
  isTouched: boolean | undefined;
  styleInLine?: CSS.Properties<string | number>;
  hiddenError?: boolean;
  isRequired: boolean;
  WrapperClassName?: string;
  maxLength?: number | undefined;
  textTransform?: boolean;
  tooltip?: string;
  prefix?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: any;
}

export const Input = (props: IPropsInput) => {
  const [inputType, setPasswordShown] = useState<TypeInput>(props.type);
  const inputIsValid = () => {
    return props.errorInput && props.isTouched;
  };
  const toggleInputType = () => {
    inputType === 'password'
      ? setPasswordShown('text')
      : setPasswordShown('password');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickDeleteAction = (event: any) => {
    if (!props.form) {
      props.onClickDeleteAction && props.onClickDeleteAction(event);
    } else {
      props.form.setFieldValue(props.name, '');
    }
    clearInput({ id: props.id, name: props.name });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateInputValue = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    let value = undefined;
    const skipTecla = ['deleteContentForward', 'deleteContentBackward'];

    if (event._reactName === 'onPaste') {
      value = event.clipboardData.getData('Text').trim();
    } else {
      value = event.target.value;
    }

    let match = false;
    switch (props.type) {
      case 'email':
        match = /^[0-9a-zA-Z@._+-]+$/.test(value.toLowerCase());
        break;
      case 'charcode':
        match = /^[A-Za-zÁÉÍÓÚáéíóúñÑ\-0-9#(),._\s]+$/.test(value);
        break;
      case 'tel':
      case 'number':
        match = /^[0-9]+$/.test(value);
        break;
      case 'decimal':
          match = /^[0-9]*\.?[0-9]*$/.test(value);
          break;
      case 'text':
        match = /^[A-Za-zÁÉÍÓÚáéíóúñÑ\s]+$/.test(value);
        break;
      case 'alphanumeric':
        match = /^[0-9A-Za-zÁÉÍÓÚáéíóúñÑ\s]+$/.test(value);
        break;
      case 'plate':
        match = /^[0-9A-Za-zÁÉÍÓÚáéíóúñÑ]+$/.test(value);
        break;
      case 'password':
        match = true;
        break;
      default:
        break;
    }

    if (!match && !skipTecla.includes(event.nativeEvent.inputType)) {
      return;
    }
    event.target.value = value;
    props.onChange({ ...event });
  };
  const setPaddingWhitPrefix = () => {
    return {
      paddingLeft: props.prefix
        ? props.prefix.length * 2 +
          (props.prefix.length > 4 ? 20 : 10) +
          24 +
          'px'
        : '1rem',
    };
  };

  return (
    <div
      className={`container__input ${
        props.WrapperClassName ? props.WrapperClassName : ''
      }`}
    >
      <input
        className={`input__generic ${inputIsValid() ? 'error' : ''} ${
          props.textTransform ? 'uppercase' : ''
        } ${props.value ? 'value' : ''} ${props.prefix ? 'prefix' : ''}`}
        style={setPaddingWhitPrefix()}
        autoComplete="nope"
        type={inputType === 'alphanumeric' ? 'text' : inputType}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.isDisabled}
        onInput={validateInputValue}
        onBlur={props.onBlur}
        onPaste={validateInputValue}
        value={props.value}
        maxLength={props.maxLength ? props.maxLength : 100}
      />
      {props.label && (
        <label
          className={`input__label ${inputIsValid() ? 'label--error' : ''} ${
            props.value || props.placeholder || props.prefix
              ? 'input__label--value'
              : ''
          } ${props.isDisabled ? 'input__label--disabled' : ''}`}
          htmlFor={props.id}
        >
          {props.label}
          {props.isRequired && (
            <img
              className="input__image__required"
              src={Images.inputRequired}
              alt="Campo requerido"
            />
          )}
        </label>
      )}
      <span
        className={`input__generic__prefix ${
          props.value || props.prefix ? 'block' : ''
        }`}
      >
        {props.prefix}
      </span>

      <div className="input__button__action">
        {props.type === 'password' && (
          <button
            className="input__button__toggle__type"
            type="button"
            onClick={toggleInputType}
          >
            <img
              src={
                inputType === 'password'
                  ? Images.inputShowPassword
                  : Images.inputHidePassword
              }
              alt="Cambiar tipo de input"
            />
          </button>
        )}
        {props.value && props.onClickDeleteAction ? (
          <ButtonDelete
            isDisabled={props.isDisabled}
            error={inputIsValid()}
            onClick={(event) => {
              !props.isDisabled && onClickDeleteAction(event);
            }}
          />
        ) : (
          ''
        )}
        {props.tooltip && <Tooltip label={props.tooltip}></Tooltip>}
      </div>
      {!props.hiddenError && inputIsValid() && (
        <span className="input__error">{props.errorLabel}</span>
      )}
    </div>
  );
};
