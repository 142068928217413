import { Images } from 'assets/images/images.index';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import './Button-more-options.scss';

export interface ICancelMoreOptions {
  id?: number | string;
  isRefund?: boolean;
}

interface IPropsButtonMoreOptions {
  id: number | string;
  label: string;
  onClickDetail?: (id: number | string) => void;
  onClickEdit?: (id: number | string) => void;
  onClickDelete?: (id: number | string) => void;
  onClickCancel?: ({ id, isRefund }: ICancelMoreOptions) => void;
  onClickCustomAction?: (id: number | string) => void;
  onClickRenewal?: (id: number | string) => void;
  onClickAddAddOns?: (id: number | string) => void;
  testid: string;
  labelDeleteOption?: string;
  labelCancelWithRefund?: string;
  labelCancelWithoutRefund?: string;
}

export const ButtonMoreOptions = (props: IPropsButtonMoreOptions) => {
  const myContainer = useRef(null);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [styleDropDown, setStyleDropDown] =
    useState<CSSProperties | undefined>(undefined);

  const openDropDown = () => {
    setShowDropDown((value) => !value);
  };
  useEffect(() => {
    if (myContainer.current) {
      const div = myContainer.current as HTMLElement;
      const rect = div.getBoundingClientRect();
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const styles = { right: 'auto', top: 'auto' };
      if (rect.right > clientWidth - 10) {
        styles.right = 0 + 'px';
      }
      if (rect.bottom > clientHeight) {
        styles.top = clientHeight - rect.top - 128 + 'px';
      }
      setStyleDropDown({ ...styles });
    }
  }, [showDropDown]);
  const closeDropDown = () => {
    setStyleDropDown(undefined);
    setShowDropDown(false);
  };

  return (
    <>
      <div className="button__more__container">
        {showDropDown && (
          <div
            className="button__more__options"
            ref={myContainer}
            style={styleDropDown}
          >
            {props.onClickDetail && (
              <div
                className="button__more__option font--bold"
                onClick={() => {
                  closeDropDown();
                  props.onClickDetail && props.onClickDetail(props.id);
                }}
              >
                Ver {props.label}
              </div>
            )}
            {props.onClickEdit && (
              <div
                className="button__more__option font--bold"
                onClick={() => {
                  closeDropDown();
                  props.onClickEdit && props.onClickEdit(props.id);
                }}
              >
                Editar {props.label}
              </div>
            )}
            {props.onClickAddAddOns && (
              <div
                className="button__more__option font--bold"
                onClick={() => {
                  closeDropDown();
                  props.onClickAddAddOns && props.onClickAddAddOns(props.id);
                }}
              >
                Agregar add on
              </div>
            )}
            {props.onClickDelete && (
              <div
                className="button__more__option"
                onClick={() => {
                  closeDropDown();
                  props.onClickDelete && props.onClickDelete(props.id);
                }}
              >
                {props.labelDeleteOption ? props.labelDeleteOption : 'Eliminar'}
              </div>
            )}
            {props.onClickCancel && (
              <>
                {props.labelCancelWithRefund && (
                  <div
                    className="button__more__option"
                    onClick={() => {
                      closeDropDown();
                      props.onClickCancel &&
                        props.onClickCancel({ id: props.id, isRefund: true });
                    }}
                  >
                    {props.labelCancelWithRefund
                      ? props.labelCancelWithRefund
                      : 'Cancelar'}
                  </div>
                )}
                {props.labelCancelWithoutRefund && (
                  <div
                    className="button__more__option"
                    onClick={() => {
                      closeDropDown();
                      props.onClickCancel &&
                        props.onClickCancel({ id: props.id, isRefund: false });
                    }}
                  >
                    {props.labelCancelWithoutRefund
                      ? props.labelCancelWithoutRefund
                      : 'Cancelar sin reembolso'}
                  </div>
                )}
              </>
            )}
            {props.onClickCustomAction && (
              <div
                className="button__more__option"
                onClick={() => {
                  closeDropDown();
                  props.onClickCustomAction &&
                    props.onClickCustomAction(props.id);
                }}
              >
                Información de pago
              </div>
            )}
            {props.onClickRenewal && (
              <div
                className="button__more__option"
                onClick={() => {
                  closeDropDown();
                  props.onClickRenewal && props.onClickRenewal(props.id);
                }}
              >
                Renovar
              </div>
            )}
          </div>
        )}
        <button
          onClick={openDropDown}
          className="button__more__btn"
          data-testid={`testid_item_${props.testid}`}
        >
          {[1, 2, 3].map((item, index) => (
            <img
              key={item + index}
              className="button__more__icon"
              src={Images.iconButtonMore}
              alt="icono boton mas opciones"
            />
          ))}
        </button>
      </div>
      {showDropDown && (
        <div className="overlay__mask" onClick={closeDropDown}></div>
      )}
    </>
  );
};
