import { BillingSvg } from 'assets/icons/billing';
import { HistorySvg } from 'assets/icons/history';
import { Icons } from 'assets/icons/icons.index';
import { ItemMenuList } from 'components';
import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import './Menu-list.scss';

export const MenuList = () => {
  const user = useSelector((state: IAppReducer) => state.authReducer);

  return (
    <nav className="menu__list">
      <ItemMenuList
        label="Mi perfil"
        link={'/settings/profile'}
        icon={Icons.profile}
        id="option-profile"
        subTitle="Actualiza aquí tus datos personales"
      />
      <ItemMenuList
        label="Mis Propiedades"
        active={true}
        link={'/settings/properties'}
        icon={Icons.properties}
        id="option-properties"
        subTitle="Tus autos, hogares y mascotas"
      />
      <ItemMenuList
        label="Pagos y planes"
        link={'/settings/payments'}
        icon={<BillingSvg color="primary" className="menu__list__icon" />}
        id="option-payments"
        subTitle="Actualiza tus planes y ve tu facturación"
      />
      <ItemMenuList
        label="Historial de Servicios"
        link={'/settings/services-history'}
        icon={<HistorySvg color="primary" className="menu__list__icon" />}
        id="option-history"
        subTitle="Revisa el historial de tus servicios"
      />
      {user.role === 'customer service' && (
        <ItemMenuList
          label="UserImpersonation"
          link={'/sales'}
          icon={Icons.contactCenter}
          id="option-agent"
          subTitle="Acceso a suscripciones de clientes"
        />
      )}
    </nav>
  );
};
