import { Images } from 'assets/images/images.index';
import { Button } from 'components/atoms/Button/Button';
import { Divider } from 'components/atoms/Divider/Divider';
import { Link } from 'components/atoms/Link/Link';
import { IAppReducer } from 'models/reducer.interface';
import { IdataResumenPlan } from 'pages/Create-account/store/state/create-account.state';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatPrice } from 'utils/payments.utils';
import './Payment-confirmation.scss';

export const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const {
    checkoutReducer: { email, name, resumen, order },
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);

  return (
    <div className="payment__confirmation__container container__generic">
      <div className="payment__confirmation__header">
        <img
          className="logo__connect"
          src={Images.connetLogoWhite}
          alt="Logo connect"
        />
        <img className="logo__check" src={Images.checkWhite} alt="Logo check" />
        <h4>¡Gracias, {name}!</h4>
        <h1>
          Todo listo <br /> para iniciar
        </h1>
        <p>
          Tu plan estará{' '}
          <strong>
            activo 72 <br /> horas después
          </strong>{' '}
          de la confirmación de <br /> datos de tu{' '}
          {(resumen?.category as []).join(', ').toLocaleLowerCase()}.
        </p>
      </div>
      <div className="payment__confirmation__body">
        <div className="payment__confirmation__info">
          <p>
            Hemos enviado un email con los detalles y documentación a {email}.
            <br />
            <br />
            Aquí tienes un resumen:
          </p>
          <div className="payment__confirmation__info__items">
            Pedido:<span>{`${order?.id}`}</span>
          </div>
          <div className="payment__confirmation__info__items">
            Fecha de compra:<span>{`${new Date().toLocaleDateString()}`}</span>
          </div>
          <Divider margin="small"></Divider>
          <div className="payment__confirmation__info__items column">
            Plan{(resumen?.plans?.length as number) > 1 ? 'es' : ''} de seguro:
            <br />
            <span>
              {(resumen?.plans as []).map((plan: IdataResumenPlan, index: number) => (
                <div key={plan.title}
                     className='payment__confirmation__plans__info'>
                  <span>{`${plan.title} | ${(resumen?.category as [])[index]}`}</span>
                  <a
                  target="_blank"
                  rel="noreferrer"
                  href={plan.url_policies}
                >Términos y condiciones</a>
              </div>
              ))}
            </span>
          </div>
          <br />
          {order?.dataOrderCompleted?.products[0]?.profit_title && (
            <div className="payment__confirmation__info__items column">
              Add On:{' '}
              <span>{`${order?.dataOrderCompleted?.products[0]?.profit_title}`}</span>
            </div>
          )}
          <Divider margin="small"></Divider>
          <div className="payment__confirmation__info__items">
            Forma de pago:<span>Credit Card</span>
          </div>
          <div className="payment__confirmation__info__items">
            Costo de la suscripción: ${' '}
            <span>
              {formatPrice(
                !order?.dataOrderCompleted?.products[0]?.profit_price
                  ? (resumen?.subTotal as number)
                  : (resumen?.subTotal as number) -
                      (order?.dataOrderCompleted?.products[0]
                        ?.profit_price as number) *
                        (order?.dataOrderCompleted?.products[0]
                          ?.quantity as number),
                branch
              )}
            </span>
          </div>
          {order?.dataOrderCompleted?.products[0]?.profit_price && (
            <div className="payment__confirmation__info__items">
              Costo de add on ${' '}
              <span>
                {formatPrice(
                  (order?.dataOrderCompleted?.products[0]
                    ?.profit_price as number) *
                    (order?.dataOrderCompleted?.products[0]
                      ?.quantity as number),
                  branch
                )}
              </span>
            </div>
          )}
          {resumen?.discount ? (
            <div className="payment__confirmation__info__items">
              Descuento: ${' '}
              <span>{formatPrice(resumen?.discount as number, branch)}</span>
            </div>
          ) : (
            ''
          )}
          {resumen?.discountCoupon || resumen?.discountForFreeTrial ? (
            <div className="payment__confirmation__info__items">
              {order?.dataOrderCompleted?.coupon?.name
                ? order.dataOrderCompleted.coupon.name
                : 'Descuento'}
              : ${' '}
              {formatPrice(
                (resumen?.discountCoupon ?? 0) +
                  (resumen?.discountForFreeTrial ?? 0),
                branch
              )}
            </div>
          ) : (
            ''
          )}
          <br />
          <div className="payment__confirmation__info__items">
            Total pagado: ${' '}
            <span>
              {formatPrice(
                resumen?.discountForFreeTrial
                  ? (resumen?.price as number) - resumen?.discountForFreeTrial
                  : (resumen?.price as number),
                branch
              )}
            </span>
          </div>
          <br />
          <div className="payment__confirmation__info__items">
            <span>
              Agradecemos tu confianza y estamos aquí para ayudarte. <br />
              <br />
              Saludos cordiales, <br /> Connect Assistance.
            </span>
          </div>
        </div>
        <div className="payment__confirmation__info__container__one">
          <div className="payment__confirmation__info__title">
            <h1> ¿Quieres gestionar tu servicio más fácilmente?</h1>
            <span>
              Visita nuestro sitio web y <br /> crea una cuenta.
            </span>
          </div>
          <Button
            className="payment__confirmation__info__button"
            template="primary"
            size="medium"
            typeStyle="rounded"
            color="secondary"
            onClick={() => navigate('/create-password')}
          >
            registrarse
          </Button>
        </div>
        <div className="payment__confirmation__support">
          <div className="payment__confirmation__support__divider"></div>
          <span>Para soporte, contáctanos</span>
          <Link
            type="primary"
            label="Ingresa aquí"
            onClick={() => navigate('/create-password')}
          ></Link>
        </div>
      </div>
    </div>
  );
};
